<template>
  <div class="containers">
    <div class="rightCon">
      <div class="main">
        <ul class="animent clearfix">
          <li v-for="(item,index) in childHeadline" :key="item.id" :style="{'border' : index === childHeadline.length-1 ? 'none' : 'block' }">
<!--            <a href="javascript:void(0)">-->
              <router-link :to="item.path">
                <img :src="getImgUrl(item.homepageImg)" alt="">
                <h3>{{ item.headlineName }}</h3>
                <div v-html="item.introduction" class="pBox"></div>
              </router-link>
<!--            </a>-->
          </li>
        </ul>
          <!--<div class="msgDiv">
              <p class="msgTitle">网站升级公告</p>
              <p>尊敬的用户您好：</p>
              <p class="msgCenter">因系统维护升级<span>2022年9月23日18:00-9月26日6:00</span>暂时停止服务，</p>
              <p>由此给您带来不便敬请谅解。</p>
              <p class="magFooter">2022年9月16日</p>
          </div>-->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import { FirsrPageurl, getImgUrl } from '@/api/index.js';
export default {
  name: "Home",
  components: {
    HelloWorld,
  },
  data() {
    return {
      childHeadline: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {

    // 获取数据
    getData() {
      FirsrPageurl().then(res => {
        if (res.success) {
          this.childHeadline = res.data;
        }
      })
              .catch(error => {
                console.log(error)
              });
    },
      getImgUrl(url) {
          return getImgUrl(url)
      },
  }
};
</script>
<style lang="less" scoped>
.containers{
  width: 100%;
  height: 100vh;
  background: url(http://www.juyike.cn/Public/Home/images/homeBg.jpg);
  background-size: cover;
    .animent{ position: absolute;}
    .msgDiv{
        position: relative;
        left: 27%;
        top: 58%;
        font-size: 20px;
        width: 42%;
        color: #fbeacd;
        text-shadow: 1px 1px 2px #333;
        .msgTitle { text-align: center; color:#ff2303; font-size:38px; text-shadow: 2px 2px 5px #666; font-weight: bolder;}
        .msgCenter{ text-indent: 40px; white-space:nowrap; span{color: #ff2303; display: inline; padding:  0 4px;}}
        .magFooter { text-align: right;}
    }
  /*.rightCon {*/
  /*  width: 87.5%;*/
  /*  min-width: 1050px;*/
  /*  left: 12.5%;*/
  /*  position: absolute;*/
  /*  top: 0;*/
  /*  ul{*/
  /*    display:flex;*/
  /*    justify-content: space-between;*/
  /*    width: 100%;*/
  /*    height: 100%;*/
  /*    li{*/
  /*      list-style: none;*/
  /*      border-right: 1px solid #ccc;*/
  /*      width: 35%;*/
  /*      a{*/
  /*        width: 100%;*/
  /*        text-decoration: none;*/
  /*        img{width: 100%;height: 100%;position: relative;top: 5%;}*/
  /*        h3{*/
  /*          color: #edc108;*/
  /*          top: 35%;*/
  /*          text-shadow: 2px 2px 5px #666;*/
  /*          font-size: 38px;*/
  /*          font-weight: bold;*/
  /*          clear: both;*/
  /*          position: absolute;*/
  /*          width: 33%;*/
  /*          text-align: center;*/
  /*        }*/
  /*        p{*/
  /*            color: #fff;*/
  /*            top: 45%;*/
  /*            font-size: 20px;*/
  /*            text-shadow: 1px 1px 2px #333;*/
  /*            position: absolute;*/
  /*            width: 33%;*/
  /*            text-align: center;*/
  /*        }*/
  /*      } */
  /*    }*/
  /*  }*/
  /*}*/
}
body{height:100%; overflow: hidden;}
.wrap{
  background:url(http://www.juyike.cn/Public/Home/images/homeBg.jpg) no-repeat center 0;
  height:100%;
  width:100%;
  position: absolute;
  background-attachment:fixed;
  background-size: cover;
  -webkit-background-size: cover;/* 鍏煎Webkit鍐呮牳娴忚鍣ㄥChrome鍜孲afari */
  -o-background-size: cover;/* 鍏煎Opera */
  zoom: 1; }
.leftNav{
  width:12.5%;
  min-width:150px;
  background:#3c3c3c;
  position: fixed;
  top:0;
  left:auto;
  z-index: 55;
  -webkit-animation:leftNav .7s 3s ease both;-moz-animation:leftNav .7s 3s ease both;animation:leftNav .7s 3s ease both
}
@keyframes leftNav{
  0%{left:-12.5%;}
  100%{left:0;}
}
.rightCon{
  width:87.5%;
  min-width:1050px;
  left:12.5%;
  position:absolute;
  top:0;
}

.rightCon .main{position: absolute; z-index: 15;top:0; width:100%;height: 80%}
.rightCon .animent{height:100%;}
.rightCon .animent li{width:33%; float: left;height: 100%;background:url(../assets/homelist.jpg) no-repeat right 0;padding-top:7%;}
.rightCon .animent li:nth-of-type(1){
  -webkit-animation:gozr 2s 4s ease both;
  -moz-animation:gozr 2s 4s ease both;
  animation:gozr 2s 4s ease both
}
@-webkit-keyframes gozr{
  0%{opacity:0;}
  100%{opacity:1;}
}
@-moz-keyframes gozr{
  0%{opacity:0;}
  100%{opacity:1;}
}
@keyframes gozr{
  0%{opacity:0;}
  100%{opacity:1;}
}

.rightCon .animent li:nth-of-type(2){
  -webkit-animation:gzzr 2s 5s ease both;
  -moz-animation:gzzr 2s 5s ease both;
  animation:gzzr 2s 5s ease both
}
@-webkit-keyframes gzzr{
  0%{opacity:0;}
  50%{opacity:0.5;}
  100%{opacity:1;}
}
@-moz-keyframes gzzr{
  0%{opacity:0;}
  50%{opacity:0.5;}
  100%{opacity:1;}
}
@keyframes gzzr{
  0%{opacity:0;}
  50%{opacity:0.5;}
  100%{opacity:1;}
}

.rightCon .animent li:nth-of-type(3){
  background:none;
  -webkit-animation:jszr 2s 6s ease both;
  -moz-animation:jszr 2s 6s ease both;
  animation:jszr 2s 6s ease both;
  width:34%;
}
@-webkit-keyframes jszr{
  0%{opacity:0;}
  50%{opacity:0.5;}
  100%{opacity:1;}
}
@-moz-keyframes jszr{
  0%{opacity:0;}
  50%{opacity:0.5;}
  100%{opacity:1;}
}
@keyframes jszr{
  0%{opacity:0;}
  50%{opacity:0.5;}
  100%{opacity:1;}
}

.rightCon .animent li a{
  width:100%;
  margin:0 auto;
  display: block;
  position: relative;
  height:100%;
}
.rightCon .animent h3,.rightCon .animent .pBox{
  position: absolute;
  z-index: 20;
  width:100%;
  text-align:center;
}
.rightCon .animent h3{
  color:#edc108;
  top:23%;
  text-shadow:2px 2px 5px #666;
  font-size:38px;
  font-weight: bold;
}
.rightCon .animent .pBox{color:#fff;top:33%; font-size:20px;text-shadow:1px 1px 2px #333;}
.rightCon .animent li a img{opacity: 0;transition: opacity 1s;margin-top:-10%;}
.rightCon .animent li a:hover img{opacity:.7;}

.zrjkLogo{
  background:url(../assets/zrjklogo2020.png) no-repeat;
  width: 203px;
  height: 151px;
  position: absolute;
  right:50%;
  display: inline-block;
  font-size: 0px;
  line-height: 0px;
  bottom:50%;
  z-index: 100;
  margin-right:-101px;
  -webkit-animation:logo 3s .4s ease both;-moz-animation:logo 3s .4s ease both;animation:logo 3s .4s ease both
}

@-webkit-keyframes logo{
  0%{opacity:0;transform:scale(1)}
  50%{opacity:1;transform:scale(1)}
  100%{opacity:0;transform:scale(1)}
}
@-moz-keyframes logo{
  0%{opacity:0;transform:scale(1)}
  50%{opacity:1;transform:scale(1)}
  100%{opacity:0;transform:scale(1)}
}
@keyframes logo{
  0%{opacity:0;transform:scale(1)}
  50%{opacity:1;transform:scale(1)}
  100%{opacity:0;transform:scale(1)}
}
</style>
